<template>
  <div>
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
      scrollable
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between align-center primary white--text pb-4">
          <div>
            Competitor Check
          </div>

          <div class="d-flex justify-center align-center">
            <!-- If there are more than one influencers -->
            <div
              v-if="influencers.length > 1"
              class="text-subtitle-2"
            >
              {{ influencers.length }} Influencers
            </div>

            <template
              v-if="influencers.length === 0 && !influencer"
            >
              <span class="mr-4 text-subtitle-2">
                Multiple Query?
              </span>

              <v-switch
                v-model="isMultiple"
                @change="inputValue = ''"
                :disabled="isMakingRequest"
                :readonly="influencers.length > 0"
                class="mt-0 mr-0"
                color="accent"
                hide-details
                inset
              ></v-switch>
            </template>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- If there are error messages -->
          <p
            v-for="(message, index) in errorMessages"
            :key="index"
            class="red--text"
          >
            {{ message }}
          </p>

          <!-- Show the error messages here -->
          <p v-if="items.length > maxItems" class="red--text">Please enter a maximum of {{ maxItems }} links at once</p>

          <!-- Show error messages for all invalid profile/links -->
          <p v-for="(item, index) in invalidItems" :key="index" class="red--text">
            <span v-if="isMultiple">
              Link #{{ items.indexOf(item) + 1 }} ({{ item }})
            </span>
            <span v-else>
              Link
            </span>

            <span>
              is not valid
            </span>
          </p>

          <div
            v-if="influencer"
            class="d-flex align-center"
          >
            <v-img
              height="30"
              width="30"
              max-width="30"
              class="mr-3"
              :src="platformIconMap[influencer.platform]"
            />

            <profile-chip
              :data="influencer.preview || influencer"
              :platform="influencer.platform"
            />
          </div>

          <div
            v-else
            class="d-flex"
          >
            <!-- show the input for searching terms -->
            <profile-selector
              v-if="!isMultiple"
              @change="handleProfileChange"
              :platform="selectedPlatform"
              :use-combobox="true"
              :hide-no-data="true"
              type="search"
              label="Search Profile"
              class="rounded-tr-0 rounded-br-0"
              outlined
            />

            <!-- the select options for different platforms -->
            <platform-selector
              :value="selectedPlatform"
              @input="handlePlatformChange"
              :show-youtube="false"
              :class="{ 'rounded-tl-0 rounded-bl-0': !isMultiple }"
              :full-width="isMultiple"
              outlined
            />
          </div>

          <!-- Show the title input -->
          <v-text-field
            v-model="formData.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.formData.title.$touch()"
            @blur="$v.formData.title.$touch()"
            :hide-details="!$v.formData.title.$anyError"
            :error-messages="$v.formData.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="my-6"
            outlined
          ></v-text-field>

          <!-- Show the time period selector -->
          <v-select
            v-model="formData.monthDuration"
            label="Time Period"
            :items="monthDurationOptions"
            hide-details
            class="mb-6"
            outlined
          ></v-select>

          <!-- Show the input for queries -->
          <v-combobox
            v-model="formData.queries"
            label="Search queries"
            :allow-overflow="true"
            placeholder="#hashtag @mention keyword, press enter to separate them"
            hint="Enter hashtags, mentions or keywords to search for"
            :error-messages="$v.formData.queries.$anyError ? [getQueriesErrorMessages()] : null"
            multiple
            outlined
            small-chips
            deletable-chips
          ></v-combobox>

          <!-- Show the textarea input for bulk -->
          <v-textarea
            v-if="isMultiple"
            outlined
            hide-details
            v-model="inputValue"
            :placeholder="`Profile usernames/URLs\nSeparate each link with a new line\nMaximum of ${maxItems} links allowed`"
          />
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="shouldCTABeDisabled"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, maxLength } from "vuelidate/lib/validators"
import platformRegex from "@/helpers/platformRegex"
import isURL from "@/helpers/isURL"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Define function for initial form data
const initialFormData = () => ({
  title: "",
  queries: [],
  monthDuration: 1
})

// Export the SFC
export default {
  // Name of the component
  name: "CompetitorCheckForm",

  // Register the components
  components: {
    ProfileChip,
    ProfileSelector,
    PlatformSelector
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    source: {
      type: String,
      default: "self"
    },

    influencer: {
      type: Object,
      required: false,
      default: null
    },

    influencers: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,

    formData: initialFormData(),
    selectedInfluencers: [],

    isMultiple: false,
    inputValue: "",

    minItems: 1,

    // The error messages for invalid inputs
    errorMessages: [],

    monthDurationOptions: [
      { text: "1 month", value: 1 },
      { text: "3 months", value: 3 },
      { text: "6 months", value: 6 },
      { text: "12 months", value: 12 }
    ]
  }),

  // Define local computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("competitor-check")
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.$store.getters["competitorCheck/selectedPlatform"]
      },

      set(value) {
        this.$store.dispatch("competitorCheck/updateSelectedPlatform", value)
      }
    },

    /**
     * Get the selected influencer
     */
    selectedInfluencer: {
      get() {
        return this.$store.getters["competitorCheck/selectedInfluencer"]
      },

      set(value) {
        this.$store.dispatch("competitorCheck/updateSelectedInfluencer", value)
      }
    },

    /**
     * Compute the maximum items allowed
     *
     * @returns {Number}
     */
    maxItems() {
      return Math.min(this.availableModuleUsage, 10)
    },

    /**
     * Compute the input string and try to generate an array
     *
     * @returns {Array}
     */
    items() {
      const breakCharacter = "*STRING&ENDS&HERE*"

      return this.inputValue
        .replaceAll(" ", breakCharacter)
        .replaceAll("\n", breakCharacter)
        .split(breakCharacter)
        .map((item) => item.trim())
        .filter((item) => Boolean(item))
    },

    /**
     * Make sure that all the items are valid string values, otherwise return an array of line numbers which are invalid
     *
     * @returns {Array}
     */
    invalidItems() {
      return this.items.filter((item) => {
        // Check if the input is kind of an URL
        if (isURL(item)) {
          // Otherwise, try getting the regex match for it
          const match = item.match(platformRegex[this.selectedPlatform])

          // If the match is found, hence found the userID for this link
          if (match !== null && match[1]) {
            return false
          } else {
            return true
          }
        }
        // Fallback to true
        else {
          // Fallback
          return false
        }
      })
    },

    /**
     * Whether or not the submit button be disabled
     *
     * @returns {Boolean}
     */
    shouldCTABeDisabled() {
      // If it's making a request
      if (this.isMakingRequest) { return true }

      // If there isn't enough reports available
      if (this.availableModuleUsage < this.minItems) { return true }

      // If there are less than minimum items
      if (this.isMultiple && this.items.length < this.minItems) { return true }

      // If there are more items than the maximum
      if (this.isMultiple && this.items.length > this.maxItems) { return true }

      // If there are invalid items
      if (this.isMultiple && this.invalidItems.length > 0) { return true }

      // If there's some invalid input
      if (this.isMultiple && this.errorMessages.length > 0) { return true }

      // Otherwise
      return false
    },

    /**
     * Get the platforms from the influencers
     *
     * @returns {Array}
     */
    platforms() {
      return Array.from(new Set(this.influencers.map(influencer => influencer.platform)))
    }
  },

  // Define the validations
  validations: {
    formData: {
      title: {
        maxLength: maxLength(100)
      },

      queries: {
        required
      }
    }
  },

  // Define watcher properties
  watch: {
    // Track changes for "influencers" prop
    influencers: {
      deep: true,
      handler(items) {
        // Reset the error messages
        this.errorMessages = []

        this.inputValue = ""

        // If there are no items selected
        if (items.length === 0) {
          // Stop further execution
          return
        }

        // If there are multiple platforms
        if (this.platforms.length > 1) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on a single platform at a time")
        }

        // If the platforms has anything but instagram or tiktok
        if (this.platforms.some((platform) => !["instagram", "tiktok"].includes(platform))) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on Instagram or TikTok")
        }

        // If there are no error messages
        if (this.errorMessages.length > 0) {
          // Stop further execution
          return
        }

        // If there are more than 1 influencers
        if (items.length > 1) {
          // Mark it as multiple
          this.isMultiple = true
        }

        // Loop through each influencer
        for (const item of items) {
          // Append the value
          this.inputValue += item.username + "\n"
        }

        // Reset the form data
        this.formData = initialFormData()
        this.$v.$reset()
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle profile change event
     *
     * @param {Object} profile
     */
    handleProfileChange(profile) {
      this.selectedInfluencer = profile
    },

    /**
     * Handle platform change event
     *
     * @param {Object} platform
     */
    handlePlatformChange(platform) {
      this.selectedPlatform = platform
    },

    /**
     * Get the error message for hashtag input
     *
     * @returns {String}
     */
    getQueriesErrorMessages() {
      // If the user has not entered any queries
      if (this.formData.queries.length === 0) {
        // Return the error message
        return "Please enter at least one keyword"
      }

      // If the user has entered more than 50 queries
      if (this.formData.queries.length > 50) {
        // Return the error message
        return "Please enter at most 50 search terms"
      }

      // If the user has entered invalid queries
      if (this.$v.formData.queries.$anyError) {
        // Return the error message
        return "Please enter valid search terms"
      }

      // Otherwise, return null
      return null
    },

    /**
     * Handle the form submission
     *
     * @return {void}
     */
    async handleSubmit() {
      // If we're already making a request
      if (this.isMakingRequest) {
        return
      }

      // Validate the form
      await this.$v.$touch()

      // Check if there's no influencer
      if (!this.isMultiple && !this.selectedInfluencer && !this.influencer) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please select an influencer" })

        // Return early
        return
      }

      // Check if the form is invalid
      if (this.$v.$invalid) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please fix the errors in the form" })

        // Return early
        return
      }

      // If there's queries error messages
      if (this.getQueriesErrorMessages()) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: this.getQueriesErrorMessages() })

        // Return early
        return
      }

      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Keep an error counter
      let successCounter = 0

      // Get the usernames to go through
      const usernames = this.isMultiple ? this.items : [this.influencer ? this.influencer.username : this.selectedInfluencer.username || this.selectedInfluencer]

      // Loop through usernames
      for (const username of usernames) {
        // Make the network request
        try {
          // Get the response
          await axios({
            url: "/api/competitor-checks",
            method: "POST",
            data: {
              title: this.formData.title,
              queries: this.formData.queries,
              month_duration: this.formData.monthDuration,

              platform: this.influencer ? this.influencer.platform : this.selectedPlatform,
              username: username,

              source: this.source,
              sharing_access: "self"
            }
          })

          // Increment the success counter
          successCounter++
        } catch (error) {
          // Log the error
          logger({ type: "CompetitorCheck/Form/Create Error", error })

          // Show an error toast
          this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred!" })
        }
      }

      // Hide the loader
      this.isMakingRequest = false
      this.$store.dispatch("loaders/remove", loaderId)

      // Emit the close event
      this.$emit("input", false)
      this.$emit("created")

      // Reset the form data
      this.formData = initialFormData()
      this.inputValue = ""
      this.$v.$reset()

      // Refresh the user's balance
      fetchProfile()

      // If the success counter is there
      if (successCounter > 0) {
        // Show the success message
        this.$store.dispatch("toasts/add", {
          text: "Your report is being processed, we'll notify you once it's ready"
        })
      }
    }
  }
}
</script>
